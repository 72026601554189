// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
  margin: 1em 0;
}
.table span {
  border-bottom: 1px solid #2878F0;
  border-left: 1px solid #2878F0;
  padding: 0.25em;
}
.table span:last-child {
  border-right: 1px solid #2878F0;
}
.table .center {
  text-align: center;
}
.table .money {
  text-align: right;
}
.table .header {
  margin-top: 1em;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
}
.table .header span {
  background-color: #E3F0FC;
}
.table .header:first-child span {
  border-top: 1px solid #2878F0;
}
.table .bookkeeping-invoice {
  display: grid;
  grid-template-columns: 15% 10% 5% 5% 1fr 10% 10%;
  padding-left: 2px;
}
.table .bookkeeping-invoice.header {
  color: #FFF;
  font-weight: 400;
  text-shadow: 0 1px 1px #606060;
}
.table .bookkeeping-invoice.header span {
  background-color: #056ED2;
}
.table .bookkeeping-componentInvoice {
  padding-bottom: 5em;
}
.table .componentInvoice-container {
  padding-left: 1em;
}
.table .componentInvoice-container .componentInvoice {
  display: grid;
  grid-template-columns: 10% 10% 10% 18% 18% 18%;
}
.table .componentInvoice-container .invoiceRow {
  margin-left: 2em;
  display: grid;
  grid-template-columns: 20% 10% 1fr;
}
`, "",{"version":3,"sources":["webpack://./src/billing/combined-preview.less"],"names":[],"mappings":"AAEA;EACE,aAAA;AADF;AAAA;EAGI,gCAAA;EACA,8BAAA;EACA,eAAA;AAAJ;AACI;EACE,+BAAA;AACN;AARA;EAWI,kBAAA;AAAJ;AAXA;EAcI,iBAAA;AAAJ;AAdA;EAiBI,eAAA;EACA,kBAAA;EACA,sBAAA;EACA,iBAAA;AAAJ;AApBA;EAsBM,yBAAA;AACN;AACI;EACE,6BAAA;AACN;AA1BA;EA6BI,aAAA;EACA,gDAAA;EACA,iBAAA;AAAJ;AACI;EAIE,WAAA;EACA,gBAAA;EACA,8BAAA;AAFN;AAJI;EAEI,yBAAA;AAKR;AAvCA;EA0CI,mBAAA;AAAJ;AA1CA;EA6CI,iBAAA;AAAJ;AA7CA;EA+CM,aAAA;EACA,8CAAA;AACN;AAjDA;EAmDM,gBAAA;EACA,aAAA;EACA,kCAAA;AACN","sourcesContent":["@import \"styles/variables.less\";\n\n.table {\n  margin: 1em 0;\n  span {\n    border-bottom: 1px solid @tulopos-brand-border-color;\n    border-left: 1px solid @tulopos-brand-border-color;\n    padding: 0.25em;\n    &:last-child {\n      border-right: 1px solid @tulopos-brand-border-color;\n    }\n  }\n  .center {\n    text-align: center;\n  }\n  .money {\n    text-align: right;\n  }\n  .header {\n    margin-top: 1em;\n    text-align: center;\n    vertical-align: middle;\n    font-weight: bold;\n    span {\n      background-color: @cb-header-bg;\n    }\n    &:first-child span {\n      border-top: 1px solid @tulopos-brand-border-color;\n    }\n  }\n  .bookkeeping-invoice {\n    display: grid;\n    grid-template-columns: 15% 10% 5% 5% 1fr 10% 10%;\n    padding-left: 2px;\n    &.header {\n      span {\n        background-color: @tulopos-tablehead-color;\n      }\n      color: #FFF;\n      font-weight: 400;\n      text-shadow: 0 1px 1px #606060;\n    }\n  }\n  .bookkeeping-componentInvoice {\n    padding-bottom: 5em;\n  }\n  .componentInvoice-container {\n    padding-left: 1em;\n    .componentInvoice {\n      display: grid;\n      grid-template-columns: 10% 10% 10% 18% 18% 18%;\n    }\n    .invoiceRow {\n      margin-left: 2em;\n      display: grid;\n      grid-template-columns: 20% 10% 1fr;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
