import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BaseRoute } from 'base-route';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { IdName, MyHttpApi, ParamsType, SalesInvoiceResponse, TransferStatus } from 'utils/api';
import { EventuallyCorrectSearch } from 'utils/eventually-correct-search';
import { BookkeepingUtil } from 'utils/bookkeeping-util';
import { state } from './state';

export interface UISalesInvoiceResponse extends SalesInvoiceResponse {
  displayName: string;
}

@autoinject
export class BillingSalesInvoiceList extends BaseRoute {
  private state = state;

  private content: UISalesInvoiceResponse[] = [];
  private clientList: IdName[] = [];

  // ECS to handle possible urlQueryParam race
  private ecs = new EventuallyCorrectSearch(
    // Lambda for passing our search params
    () => this.buildRealQueryParams(),
    // Lambda for searching. Passing plain function will destroy our this. and e.g. this.doPut will not work
    args => this.api.salesInvoiceList(args),
    // Lambda for setting data once we are done.
    data => this.applyData(data),
  );

  @observable({ changeHandler: "search" })
  private clientId?: number;
  @observable({ changeHandler: "search" })
  private status?: TransferStatus;
  @observable({ changeHandler: "search" })
  private startDate?: Date;
  @observable({ changeHandler: "search" })
  private endDate?: Date;
  @observable({ changeHandler: "search" })
  private minSum?: number;
  @observable({ changeHandler: "search" })
  private maxSum?: number;
  @observable({ changeHandler: "search" })
  private deleted: boolean = false;
  @observable({ changeHandler: "search" })
  private billedCustomerName?: string;

  protected override routeParams() {
    return {
      "clientId": Number,
      "status": String,
      "startDate": Date,
      "endDate": Date,
      "minSum": Number,
      "maxSum": Number,
      "deleted": Boolean,
      "billedCustomerName": String,
    };
  }

  private fieldList: FieldSpec[] = [
    { key: "businessDate", header: "common.date", type: "date", format: "DD.MM.YYYY", cssClass: "not-clickable" },
    { key: "unitName", header: "unit.unit", type: "text", cssClass: "not-clickable" },
    { key: "displayName", header: "client-billing.customerName", type: "text", cssClass: "not-clickable" },
    { key: "billingCustomerReference", header: "client-billing.customerReference", type: "text", },
    { key: "paymentTotal", header: "client-billing.sum", type: "number", minimumFractionDigits: 2, cssClass: "not-clickable" },
    { key: "billId", header: "client-billing.invoiceNr", type: "text", cssClass: "link" },
    { key: "status", header: "client.state", type: "enum", enum: "TransferStatusSalesInvoice", cssClass: "not-clickable" },
    { key: "deleted", header: "client-billing.hidden", type: "boolean", cssClass: "not-clickable" },
  ];

  constructor(private router: Router, private api: MyHttpApi, private i18n: I18N) {
    super();
  }

  override async activate(params: ParamsType) {
    super.activate(params);

    for (let c of await this.api.clientListOwnModules()) {
      if (c.clientTransferTarget) {
        this.clientList.push(c.client);
      }
    }
    await this.ecs.search();
  }

  async search() {
    await this.ecs.search();
  }

  applyData(data: SalesInvoiceResponse[]) {
    this.content = data.map(x => (
      { ...x, displayName: BookkeepingUtil.generateBillingDisplayName(x) }
    ));
    super.rewriteWindowUrl(this.buildQueryParams());
  }

  // For base-route
  buildQueryParams() {
    return {
      clientId: this.clientId,
      deleted: this.deleted,
      status: this.status,
      startDate: this.startDate,
      endDate: this.endDate,
      minSum: this.minSum,
      maxSum: this.maxSum,
      billedCustomerName: this.billedCustomerName,
    };
  }

  // for ECS
  buildRealQueryParams() {
    return { ...this.buildQueryParams(), lang: this.i18n.getLocale(), };
  }

  @computedFrom("content")
  get billTotal() {
    return this.content.map(p => p.paymentTotal).reduce((a, b) => a + b, 0);
  }

  rowCall(key: string, row: SalesInvoiceResponse) {
    if (key == "billId" && row.id) {
      this.state.back = this.router.currentInstruction?.config.name || '/';
      this.router.navigateToRoute("billing/handle", { id: "" + row.id });
    }
  }
}
