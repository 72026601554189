import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BaseRoute } from 'base-route';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { ClientTransferTargetMessage, IdName, MyHttpApi, ParamsType, PrepaymentResponse, TransferStatus } from 'utils/api';
import { EventuallyCorrectSearch } from 'utils/eventually-correct-search';
import { state } from './state';
import { BookkeepingUtil } from '../utils/bookkeeping-util';

export interface UIPrepaymentResponse extends PrepaymentResponse {
  displayName: string;
}

@autoinject
export class BillingSalesInvoiceList extends BaseRoute {
  private content: UIPrepaymentResponse[] = [];
  private clientList: IdName[] = [];

  private state = state;

  private fieldList: FieldSpec[] = [
    { key: "businessDate", header: "common.date", type: "date", format: "DD.MM.YYYY", cssClass: "not-clickable" },
    { key: "unitName", header: "unit.unit", type: "text", cssClass: "not-clickable" },
    { key: "displayName", header: "client-billing.customerName", type: "text", },
    { key: "billingCustomerReference", header: "client-billing.customerReference", type: "text", },
    { key: "paymentTotal", header: "client-billing.sum", type: "number", minimumFractionDigits: 2, cssClass: "not-clickable" },
    { key: "billId", header: "client-billing.invoiceNr", type: "text", cssClass: "not-clickable" },
    { key: "status", header: "client.state", type: "enum", enum: "TransferStatus", cssClass: "not-clickable" },
    { key: "deleted", header: "client-billing.hidden", type: "boolean", cssClass: "not-clickable" },
  ];

  private ecs = new EventuallyCorrectSearch(
    // Lambda for passing our search params
    () => this.buildRealQueryParams(),
    // Lambda for searching. Passing plain function will destroy our this. and e.g. this.doPut will not work
    args => this.api.prepaymentPrepaymentList(args),
    // Lambda for setting data once we are done.
    data => this.applyData(data),
  );

  @observable({ changeHandler: 'search' })
  private clientId?: number;
  @observable({ changeHandler: 'search' })
  private startDate?: Date;
  @observable({ changeHandler: 'search' })
  private endDate?: Date;
  @observable({ changeHandler: 'search' })
  private minSum?: number;
  @observable({ changeHandler: 'search' })
  private maxSum?: number;
  @observable({ changeHandler: 'search' })
  private reference?: string;
  @observable({ changeHandler: 'search' })
  private status?: TransferStatus;
  @observable({ changeHandler: 'search' })
  private deleted = false;
  @observable({ changeHandler: "search" })
  private billedCustomerName?: string;

  private cttm?: ClientTransferTargetMessage;
  private showCttm = false;

  constructor(private router: Router, private api: MyHttpApi, private i18n: I18N) {
    super();
  }

  protected override routeParams() {
    return {
      "clientId": Number,
      "startDate": Date,
      "endDate": Date,
      "minSum": Number,
      "maxSum": Number,
      "reference": String,
      "status": String,
      "deleted": Boolean,
      "billedCustomerName": String,
    };
  }

  override async activate(params: ParamsType) {
    super.activate(params);
    if (this.api.session?.canBelAdmin) {
      this.fieldList.unshift({ key: "cttmId", header: "#", type: "text", cssClass: "link" });
    }
    for (let c of await this.api.clientListOwnModules()) {
      if (c.clientTransferTarget) {
        this.clientList.push(c.client);
      }
    }
    await this.search();
  }

  // For base-route
  buildQueryParams() {
    return {
      clientId: this.clientId,
      startDate: this.startDate,
      endDate: this.endDate,
      minSum: this.minSum,
      maxSum: this.maxSum,
      reference: this.reference,
      status: this.status,
      deleted: this.deleted,
      billedCustomerName: this.billedCustomerName,
    };
  }

  // for ECS
  buildRealQueryParams() {
    return { ...this.buildQueryParams(), lang: this.i18n.getLocale(), };
  }

  async search() {
    await this.ecs.search();
  }

  applyData(data: PrepaymentResponse[]) {
    this.content = data.map(x => ({ ...x, displayName: BookkeepingUtil.generateBillingDisplayName(x) }));
    super.rewriteWindowUrl(this.buildQueryParams());
  }

  @computedFrom("content")
  get billTotal() {
    return this.content.map(p => p.paymentTotal).reduce((a, b) => a + b, 0);
  }

  async rowCall(key: string, row: PrepaymentResponse) {
    if (key == "cttmId" && row.cttmId) {
      if (!this.cttm || this.cttm.id != row.cttmId) {
        this.cttm = await this.api.clientSaleBillingCttm({ id: row.cttmId });
      }
      this.showCttm = true;
    }
  }
}
