import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { state } from 'billing/state';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { BookkeepingCustomer, ClientTransferTarget, IdName, MyHttpApi, TaskRuntimeData } from 'utils/api';

interface UIBookkeepingCustomer extends BookkeepingCustomer {
  combinedInvoices: boolean;
}

@autoinject
export class BillingCustomersList {
  @observable({ changeHandler: "search" })
  private targetId?: number;
  private targetList: IdName[] = [];
  private customerList: UIBookkeepingCustomer[] = [];
  private target?: ClientTransferTarget;
  private state = state;
  private importStatus?: TaskRuntimeData<void>;

  private files?: FileList;

  private fieldList: FieldSpec[] = [
    { key: "id", header: "ID", type: "text", },
    { key: "bookkeepingId", header: "customer.bookkeepingId", type: "text" },
    { key: "officialName", header: "client-billing.officialName", type: "text" },
    { key: "name", header: "client-billing.billingName", type: "text" },
    { key: "businessId", header: "client-billing.vatId", type: "text" },
    { key: "combinedInvoices", header: "client-billing.combinedInvoices", type: "boolean" },
    { key: "version", header: "common.timestamp", type: "date", format: "DD.MM.YYYY HH:MM" }
  ];

  constructor(private api: MyHttpApi, private router: Router) {
  }

  async activate(params: { targetId: string }) {
    let targetList = await this.api.clientTransferTargetOwnList();
    targetList.sort((a,b) => a.name.localeCompare(b.name));
    this.targetList = targetList;
    if (this.targetList.length == 1 && !params.targetId) {
      this.targetId = this.targetList[0].id;
    } else if (params.targetId) {
      this.targetId = parseInt(params.targetId);
    }
    await this.search();
  }

  @computedFrom("target.strategy")
  get canAdd() {
    return (this.target && (this.target.strategy === "TALENOM_ACCOUNTING" || (this.target.strategy === "FENNOA_ACCOUNTING" && !this.target.fennoaTempActive)));
  }

  async search() {
    this.customerList = [];
    if (!this.targetId) {
      this.target = undefined;
      return;
    }
    let [customerList, target, combinedCustomers, importStatus] = await Promise.all([
      this.api.bookkeepingCustomerListByTarget({ targetId: this.targetId }),
      this.api.clientTransferTargetById({ id: this.targetId }),
      this.api.bookkeepingCustomerListByTargetWithCombinedBilling({targetId: this.targetId}),
      this.api.bookkeepingCustomerImportStatus(),
    ]);
    this.target = target;
    this.importStatus = importStatus;
    this.customerList = customerList.map(c => {
      return {...c, combinedInvoices: !!combinedCustomers.find(cc => c.id === cc.customerId)};
    });
  }

  uploadFile() {
    const targetId = this.targetId;
    if (!this.files || !targetId) {
      return;
    }
    let reader = new FileReader();
    reader.addEventListener("load", async e => {
      if (typeof reader.result === "string") {
        let file = reader.result.replace(/^[^,]+,/, "");
        await this.api.bookkeepingCustomerExcelUpload({ targetId, file, });
      }
    });
    reader.readAsDataURL(this.files[0]);
  }

  rowCall(key: string, row: BookkeepingCustomer) {
    if (row.id && row.targetId) {
      this.state.backCustomer = this.router.currentInstruction?.config.name || '/';
      this.state.backCustomerParams = this.router.currentInstruction?.queryParams || {};
      this.router.navigateToRoute("billing/customers/edit", {id: ""+ row.id, targetId: "" + row.targetId});
    }
  }
}
