import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BaseRoute } from 'base-route';
import { FieldSpec, SelectSpec } from 'components/bel-au-html-table/component';
import { BookkeepingCustomer, ClientBilling, CombinedBillingListRequest, IdName, MyHttpApi, ParamsType } from 'utils/api';
import { EventuallyCorrectSearch } from 'utils/eventually-correct-search';
import { Notify } from 'utils/notify';
import { state } from './state';
import { BookkeepingUtil } from 'utils/bookkeeping-util';
export interface UIBookkeepingCustomer extends BookkeepingCustomer {
  displayName: string;
}

export interface UICombinedBillingListResponse extends ClientBilling {
  error?: string;
  displayName: string;
}

const selectedClients: SelectSpec = {};
@autoinject
export class BillingCombinedBilling extends BaseRoute {
  private clientList: IdName[] = [];
  private customerList: BookkeepingCustomer[] = [];
  private billList: UICombinedBillingListResponse[] = [];
  private state = state;
  private selectedInvoices: SelectSpec = selectedClients;

  private ecs = new EventuallyCorrectSearch(
    // Lambda for passing our search params
    () => this.buildQueryParams(),
    // Lambda for searching. Passing plain function will destroy our this. and e.g. this.doPut will not work
    args => this.searchLambda(args),
    // Lambda for setting data once we are done.
    data => this.applyData(data),
  );

  async searchLambda(args: CombinedBillingListRequest) {
    if (args.startDate && args.endDate && args.clientId) {
      return this.api.combinedBillingListCombinable(args);
    }
    return [];
  }
  @observable({ changeHandler: 'reloadCustomers' })
  private clientId?: number;
  @observable({ changeHandler: 'search' })
  private customerId?: number;
  @observable({ changeHandler: 'search' })
  private startDate = this.lastMonth();
  @observable({ changeHandler: 'search' })
  private endDate = this.lastMonthLastDay();

  private readonly fieldList: FieldSpec[] = [
    { key: "businessDate", header: "common.date", type: "date", format: "DD.MM.YYYY" },
    { key: "displayName", header: "client-billing.customerName", type: "text", },
    { key: "billingCustomerReference", header: "client-billing.customerReference", type: "text", },
    { key: "paymentTotal", header: "client-billing.sum", type: "number", minimumFractionDigits: 2 },
    { key: "bookkeepingId", header: "client-billing.customerNr", type: "number", },
    { key: "billId", header: "client-billing.invoiceNr", type: "number", },
    { key: "error", header: "common.error", type: "text", },
  ];

  constructor(private api: MyHttpApi, private router: Router, private i18n: I18N, private notify: Notify) {
    super();
  }

  lastMonth() {
    let d = new Date();
    return new Date(Date.UTC(d.getFullYear(), d.getMonth() - 1, 1));
  }

  lastMonthLastDay() {
    let d = this.lastMonth();
    d.setMonth(d.getMonth() + 1);
    return new Date(Date.UTC(d.getFullYear(), d.getMonth(), 0));
  }

  @computedFrom('startDate')
  get month() {
    return this.startDate.getMonth() + 1;
  }
  set month(month: number) {
    if (month) {
      this.startDate = new Date(Date.UTC(this.startDate.getFullYear(), month - 1, 1));
      this.endDate = new Date(Date.UTC(this.startDate.getFullYear(), this.startDate.getMonth() + 1, 0));
    }
  }

  @computedFrom('startDate')
  get year() {
    return this.startDate.getFullYear();
  }
  set year(year: number) {
    if (year) {
      this.startDate = new Date(Date.UTC(year, this.startDate.getMonth(), 1));
      this.endDate = new Date(Date.UTC(year, this.startDate.getMonth() + 1, 0));
    }
  }

  @computedFrom("startDate")
  get startDateObserver() {
    if (this.startDate.getMonth() !== this.endDate.getMonth()) {
      this.endDate = new Date(Date.UTC(this.startDate.getFullYear(), this.startDate.getMonth(), this.endDate.getDate()));
    }
    return "";
  }

  @computedFrom("endDate")
  get endDateObserver() {
    if (this.startDate.getMonth() !== this.endDate.getMonth()) {
      this.startDate = new Date(Date.UTC(this.endDate.getFullYear(), this.endDate.getMonth(), this.startDate.getDate()));
    }
    return "";
  }

  protected override routeParams() {
    return {
      "customerId": Number,
      "clientId": Number,
      "year": Number,
      "month": Number,
      "startDate": Date,
      "endDate": Date,
    };
  }

  override async activate(params: ParamsType) {
    for (let c of await this.api.clientListOwnModules()) {
      if (c.clientTransferTarget) {
        this.clientList.push(c.client);
      }
    }
    if (this.clientList.length == 1) {
      this.clientId = this.clientList[0].id;
    }
    super.activate(params);
  }

  async reloadCustomers() {
    if (this.clientId) {
      super.rewriteWindowUrl(this.buildQueryParams());
      let customerList = await this.api.bookkeepingCustomerListByClientWithCombinedBilling({ clientId: this.clientId });
      this.customerList = customerList.map(x => ({ ...x, displayName: BookkeepingUtil.generateCustomerDropdownName(x) }));
      await this.search();
    } else {
      this.customerList = [];
    }
  }

  async search() {
    if (!this.clientId || (
      this.startDate.getFullYear() !== this.endDate.getFullYear()
      || this.startDate.getMonth() !== this.endDate.getMonth()
    )) {
      this.billList = [];
      return;
    }
    this.billList = [];
    super.rewriteWindowUrl(this.buildQueryParams());
    await this.ecs.search();
  }

  buildQueryParams() {
    return {
      customerId: this.customerId,
      clientId: this.clientId || 0,
      startDate: this.startDate,
      endDate: this.endDate,
    };
  }

  applyData(data: ClientBilling[]) {
    this.billList = data.map(d => {
      let item: UICombinedBillingListResponse = { ...d, displayName: BookkeepingUtil.generateBillingDisplayName(d) };
      const id = item.billId;
      if (!id) {
        item.error = this.i18n.tr("client-billing.combinedBillingMissingCustomer");
        return item;
      }
      if (!this.customerList.some(c => c.bookkeepingId === item.bookkeepingId)) {
        item.error = this.i18n.tr("client-billing.combinedBillingCustomerNotCombinedCustomer", { customerId: item.bookkeepingId } as {});
        return item;
      }
      return item;
    });
    // * Remove any previously selected invoices
    for (const key of Object.keys(this.selectedInvoices)) {
      const id = parseInt(key);
      if (!this.billList.find(b => b.id === id)) {
        delete this.selectedInvoices[key];
      }
    }
    super.rewriteWindowUrl(this.buildQueryParams());
  }

  async sendInvoices() {
    if (!this.clientId) {
      return;
    }
    if (!this.canSend()) {
      return;
    }
    this.state.combinedBillingPreviewList = this.getInvoiceIds();
    this.router.navigateToRoute("billing/combined-preview", { clientId: "" + this.clientId });
  }

  errors(selected: number[]) {
    return this.billList.filter(b => selected.includes(b.id)).some(b => b.error);
  }

  rowCall(key: string, row: UICombinedBillingListResponse) {
    if (row.id) {
      this.state.back = this.router.currentInstruction?.config.name || '/';
      this.router.navigateToRoute("billing/handle", { id: "" + row.id });
    }
  }

  canSend() {
    const selected = this.getInvoiceIds();
    if (!this.month) {
      this.notify.info("client-billing.errorMonth");
      return false;
    }
    if (!selected.length) {
      this.notify.info("client-billing.errorSelected");
      return false;
    }
    if (this.errors(selected)) {
      this.notify.info("client-billing.errorHasError");
      return false;
    }
    return true;
  }

  getInvoiceIds(): number[] {
    const array: number[] = [];
    for (const [key, value] of Object.entries(this.selectedInvoices)) {
      const id = parseInt(key);
      if (value && this.billList.find(b => b.id === id)) {
        array.push(id);
      }
    }
    return array;
  }
}
