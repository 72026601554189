import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BaseRoute } from 'base-route';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { BookkeepingCustomer, BookkeepingInvoicePreview, ClientTransferTargetMessage, CombinedBillingErrorMessageResponse, CombinedBillingSendStatus, IdName, MyHttpApi, ParamsType } from 'utils/api';
import { EventuallyCorrectSearch } from 'utils/eventually-correct-search';
import { QuickMonth, QuickMonthType } from 'utils/quick-month';
import { state } from './state';
import { BookkeepingUtil } from 'utils/bookkeeping-util';
export interface UIBookkeepingCustomer extends BookkeepingCustomer {
  displayName: string;
}

export interface UIBookkeepingInvoicePreview extends BookkeepingInvoicePreview {
  displayName: string;
  cancel?: string;
  resend?: string;
}

@autoinject
export class BillingCombinedBilling extends BaseRoute {
  private clientList: IdName[] = [];
  private clients: { [key: number]: { name: string; }; } = {};
  private customerList: BookkeepingCustomer[] = [];
  private billList: UIBookkeepingInvoicePreview[] = [];
  private state = state;
  private ecs = new EventuallyCorrectSearch(
    // Lambda for passing our search params
    () => this.buildQueryParams(),
    // Lambda for searching. Passing plain function will destroy our this. and e.g. this.doPut will not work
    args => this.api.combinedBillingListReady(args),
    // Lambda for setting data once we are done.
    data => this.applyData(data),
  );

  @observable({ changeHandler: 'reloadCustomers' })
  private clientId?: number;
  @observable({ changeHandler: 'search' })
  private status?: CombinedBillingSendStatus;
  @observable({ changeHandler: 'search' })
  private customerId?: number;
  @observable({ changeHandler: 'search' })
  private startDate?: Date;
  @observable({ changeHandler: 'search' })
  private endDate?: Date;
  @observable({ changeHandler: 'quickMonthTypeChanged' })
  private quickMonthType?: QuickMonthType;

  private readonly fieldList: FieldSpec[] = [
    { key: "invoiceDate", header: "common.date", type: "date", format: "DD.MM.YYYY" },
    { key: "clientId", header: "unit.unit", type: "lookup", lookupData: this.clients, lookupKey: "name", },
    { key: "displayName", header: "client-billing.customerName", type: "text", },
    { key: "invoiceMessage", header: "client-billing.invoiceMessage", type: "text", },
    { key: "total", header: "client-billing.total", type: "number", minimumFractionDigits: 2 },
    { key: "invoiceCount", header: "client-billing.invoiceCount", type: "number", },
    { key: "rowCount", header: "client-billing.rowCount", type: "number", },
    { key: "lastSendTime", header: "client-billing.lastSendTime", type: "date" },
    { key: "status", header: "client.state", type: "enum", enum: "CombinedBillingSendStatus", cssClass: "link" },
    { key: "cancel", header: "common.cancel", type: "text", cssClass: "link" },
    { key: "resend", header: "common.resend", type: "text", cssClass: "link" },
  ];

  private showModal = false;
  private errorResponse?: CombinedBillingErrorMessageResponse;
  private modalContent = "";

  private cttm?: ClientTransferTargetMessage;
  private showCttm = false;

  constructor(private api: MyHttpApi, private router: Router, private quickMonth: QuickMonth, private i18n: I18N) {
    super();
  }

  protected override routeParams() {
    return {
      "clientId": Number,
      "customerId": Number,
      "startDate": Date,
      "endDate": Date,
    };
  }

  quickMonthTypeChanged() {
    [this.startDate, this.endDate] = this.quickMonth.getDates(this.quickMonthType);
  }

  override async activate(params: ParamsType) {
    super.activate(params);
    // Default to first day of previous month to avoid fetching all the history
    if (!this.startDate) {
      let d = new Date();
      d.setMonth(d.getMonth() - 1, 1);
      this.startDate =  new Date(Date.UTC(d.getFullYear(), d.getMonth(), 1));
    }
    for (let c of await this.api.clientListOwnModules()) {
      if (c.clientTransferTarget) {
        this.clientList.push(c.client);
        this.clients[c.client.id] = { name: c.client.nickname, };
      }
    }
    if (this.clientList.length == 1) {
      this.clientId = this.clientList[0].id;
    }
    await this.reloadCustomers();
  }

  async reloadCustomers() {
    let customerList: BookkeepingCustomer[] = [];
    if (this.clientId) {
      customerList = await this.api.bookkeepingCustomerListByClientWithCombinedBilling({ clientId: this.clientId });
    } else {
      customerList = await this.api.bookkeepingCustomerListAllWithCombinedBilling();
    }
    this.customerList = customerList.map(x => ({ ...x, displayName: BookkeepingUtil.generateCustomerDropdownName(x) }));
    await this.search();
  }

  async search() {
    await this.ecs.search();
    this.state.back = this.router.currentInstruction?.config.name || '/';
  }

  // For base-route
  buildQueryParams() {
    return {
      clientId: this.clientId,
      status: <CombinedBillingSendStatus>this.status,
      customerId: this.customerId,
      startDate: this.startDate,
      endDate: this.endDate,
    };
  }

  applyData(data: BookkeepingInvoicePreview[]) {
    this.billList = data.map(x => {

      let item: UIBookkeepingInvoicePreview = { ...x, displayName: BookkeepingUtil.generateBillingDisplayName(x.cbMain) };
      if (item.status === 'ERROR') {
        item.cancel = this.i18n.tr("common.cancel");
        item.resend = this.i18n.tr("common.resend");
      }
      return item;
    });
    super.rewriteWindowUrl(this.buildQueryParams());
  }

  @computedFrom("billList")
  get billTotal() {
    return this.billList.map(p => p.total).reduce((a, b) => a + b, 0);
  }

  async rowCall(key: string, row: UIBookkeepingInvoicePreview) {
    if (key === "cancel" && row.cancel && row.cttmId) {
      await this.api.combinedBillingCancel({ cttmId: row.cttmId });
      await this.search();
    } else if (key === "resend" && row.resend && row.cttmId) {
        await this.api.combinedBillingResend({ cttmId: row.cttmId });
        await this.search();
    } else if (key === "invoiceMessage" && row.cttmId && this.api.session?.canAdmin) {
      if (!this.cttm || this.cttm.id != row.cttmId) {
        this.cttm = await this.api.clientSaleBillingCttm({ id: row.cttmId });
      }
      this.showCttm = true;

    } else if (row.status === 'ERROR' && row.cttmId) {
      if (!this.errorResponse || this.errorResponse.cttmId !== row.cttmId) {
        this.errorResponse = await this.api.combinedBillingErrorMessage({ cttmId: row.cttmId });
      }
      let miem = this.errorResponse.mappedIntegrationErrorMessage;
      if (miem) {
        if (this.lang === "fi" && miem.textFi) {
          this.modalContent = miem.textFi;
        } else if (this.lang === "en" && miem.textEn) {
          this.modalContent = miem.textEn;
        }
      } else {
        this.modalContent = this.errorResponse.status;
      }
      if (this.modalContent) {
        this.showModal = true;
      }
    }
  }

  @computedFrom("i18n.i18next.language")
  get lang() {
    return this.i18n.i18next.language;
  }
}
