import { autoinject, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BaseRoute } from 'base-route';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { IdName, MyHttpApi, ParamsType, SalesInvoiceListNotCompleteResponse } from 'utils/api';
import { EventuallyCorrectSearch } from 'utils/eventually-correct-search';
import { Notify } from 'utils/notify';
import { state } from './state';
import { BookkeepingUtil } from 'utils/bookkeeping-util';

interface UISalesInvoiceListNotCompleteResponse extends SalesInvoiceListNotCompleteResponse {
  displayName: string;
}

@autoinject
export class BillingIncompleteList extends BaseRoute {
  private content: UISalesInvoiceListNotCompleteResponse[] = [];
  private clientList: IdName[] = [];

  private state = state;

  private filteredContent: { row: SalesInvoiceListNotCompleteResponse, values: any; }[] = [];
  // ECS to handle possible urlQueryParam race
  private ecs = new EventuallyCorrectSearch(
    // Lambda for passing our search params
    () => this.buildRealQueryParams(),
    // Lambda for searching. Passing plain function will destroy our this. and e.g. this.doPut will not work
    args => this.api.salesInvoiceListNotComplete(args),
    // Lambda for setting data once we are done.
    data => this.applyData(data),
  );

  private fieldList: FieldSpec[] = [
    { key: "businessDate", header: "common.date", type: "date", format: "DD.MM.YYYY" },
    { key: "unitName", header: "unit.unit", type: "text", },
    { key: "displayName", header: "client-billing.customerName", type: "text", },
    { key: "billingCustomerReference", header: "client-billing.customerReference", type: "text", },
    { key: "paymentTotal", header: "client-billing.total", type: "number", minimumFractionDigits: 2 },
    { key: "billId", header: "client-billing.invoiceNr", type: "text", cssClass: "link" },
    { key: "status", header: "client.state", type: "text", },
  ];

  @observable({ changeHandler: "search" })
  private clientId?: number;
  @observable({ changeHandler: "search" })
  private deleted = false;

  constructor(private notify: Notify, private api: MyHttpApi, private i18n: I18N, private router: Router) {
    super();
  }

  protected override routeParams() {
    return {
      "clientId": Number,
      "deleted": Boolean,
    };
  }

  override async activate(params: ParamsType) {
    super.activate(params);
    for (let c of await this.api.clientListOwnModules()) {
      if (c.clientTransferTarget) {
        this.clientList.push(c.client);
      }
    }
    await this.search();
  }

  // For base-route
  buildQueryParams() {
    return {
      clientId: this.clientId,
      deleted: this.deleted,
    };
  }

  // for ECS
  buildRealQueryParams() {
    return { ...this.buildQueryParams(), lang: this.i18n.getLocale(), };
  }

  async search() {
    await this.ecs.search();
  }

  applyData(data: SalesInvoiceListNotCompleteResponse[]) {
    this.content = data.map(x => ({ ...x, displayName: BookkeepingUtil.generateBillingDisplayName(x) }));
    super.rewriteWindowUrl(this.buildQueryParams());
  }

  async rowCall(key: string, row: SalesInvoiceListNotCompleteResponse) {
    if (row.id) {
      this.state.back = this.router.currentInstruction?.config.name || '/';
      // * Infortunately, we need this due to order of handling...
      this.state.incompleteInvoiceList = this.filteredContent.map(x => x.row.id);
      this.router.navigateToRoute("billing/handle", { id: "" + row.id });
    }
  }
}
