import { autoinject } from 'aurelia-framework';
import { ParamsType } from "utils/api";

/**
 * Represents a constructor that has "new" operator that accepts a string, which converts value
 * from our "stringly typed" storage system (URL / SessionStorage) to the underlying JS objects. Note that e.g.
 * JS builtin Number: NumberConstructor supports invocation with Number("foo") (i.e. without "new")
 * which parses the string and returns the primitive number. All JS primitives have been special-cased to
 * avoid the object boxes.
 */
export type CtorType<V> = new (value: string) => V;
/**
 * BaseRoute functionality for use in multiple routes that benefit from remembering historical form state:
 * * Save search-criteria
 * * Parse URL-params into defined actual format
 * To use, extend the route e.g.
 * `export class BillingIncompleteList extends BaseRoute {`
 * and add
 * `override activate(params: ParamsType) {
 *    super.activate(params);`
 * to the activate() hook of route.
 * 
 * Implement routeParams() in route, e.g.
 *  `override routeParams() {
 *    return {
 *      "clientId": Number
 *    };
 *  }`
 *
 * Use types such as String, Number, Boolean for the JS primitives, and otherwise just Foo for any class
 * that is constructable by a string argument, e.g. new Foo(someString).
 * 
 * If you make immediate queries when values change based on observation, be adviced that
 * the way this class works is that it is all based on writing multiple values one at a time, and multiple
 * concurrent queries to backend may result during initialization. You probably need ECS which has a small delay
 * to debounce/throttle the initial flurry of form writes.
 * Also, you can put super.rewriteWindowsUrl(formArgs) to end of ECS applyData (final search result write)
 * to have params be remembered at URL and in SessionStorage.
 */
@autoinject
export abstract class BaseRoute {
  /**
   * Overridable method for automatic state saving and loading.
   * CtorType refers to "new" constructor function that accepts a string
   * and returns an object of some type. We have declared the return value of
   * the constructor as "any" because we can not guarantee compile time safety.
   * 
   * In other words, TypeScript will not be able to prove that the routeParams defined
   * match the type definitions of the members in the route, or even that these keys exist.
   * 
   * Formally, the relation can be described:
   * 
   * * if K is a member of Route (keyof this)
   * * and V is the static type of the key's value in Route (Route[K])
   * * then routeParams[K] must have type CtorType&lt;V&gt;.
   */
  protected abstract routeParams(): { [key: string]: CtorType<any>; };

  /**
   * Default implementation of activate() recovers URL parameters
   * into the route either from the actual URL parameters, or from saved state variables.
   * See routeParams() for how these parameters are defined.
   *
   * If you override activate() in your route, then please use:
   * 
   * `override activate(params: ParamsType) { super.activate(params); }`
   * 
   * @param params 
   */
  activate(params: ParamsType) {
    /* Prefer any URL params if we have them. Otherwise, use saved state. If neither, use route's whatever stuff it already has. */
    if (Object.keys(params).length === 0) {
      params = this.loadState();
    }
    if (Object.keys(params).length !== 0) {
      this.readParams(params);
    }
  }

  /**
   * Convert all params to objects and write them to this.
   */
  private readParams(params: ParamsType) {
    const routeParams = this.routeParams();
    for (const key in routeParams) {
      const ctor = routeParams[key];
      const value = params[key];
      let valueToWrite: any;
      if (value === undefined) {
        /* If value is missing in params, it might be defined with default in route. Use that default, do not overwrite with undefined*/
        continue;
      } else if (ctor === Boolean && value !== "") { // boolean=&someOther=bar means, that boolean is defaulted to route value
        /* Boolean(str) seems to test string truthiness, i.e. str.length > 0, so "true" and "false" both evaluate to true. */
        valueToWrite = value === "true";
      } else if (ctor === Number) {
        if (value === "") {
          continue;
        }
        /* We do not want to invoke new Number() which makes a box, instead want the primitive */
        valueToWrite = Number(value);
      } else if (ctor === String) {
        valueToWrite = value;
      } else {
        /* Invoke object constructor, probably for a Date. */
        valueToWrite = new ctor(value);
      }
      // @ts-ignore
      this[key] = valueToWrite;
    }
  }

  /**
   * Rewrites url with current query params. Also saves query params to session storage for reloading or recovering on next access.
   */
  protected rewriteWindowUrl(object: { [key: string]: any }) {
    const params = BaseRoute.objectToUrlParams(object);
    this.storeState(params);

    const route = BaseRoute.getCurrentPathWithoutQuery();
    const urlParams = new URLSearchParams(Object.entries(params));
    window.history.replaceState({}, "", route + "?" + urlParams);
  }

/**
 * Convert objects into strings that are writable to URL. Also some checks are
 * made before blindly invoking toString(), e.g. Array types and some primitives
 * are not valid for URL.
 * 
 * @param object 
 * @returns stringified flat hash that contains same keys as the object.
 */
  public static objectToUrlParams(object: { [key: string]: any }): ParamsType {
    const params: ParamsType = {};
    for (const [key, value] of Object.entries(object)) {
      const type = typeof value;
      if (value === undefined) {
        /* undefined values are left missing */
      } else if (value instanceof Date) {
        params[key] = value.toJSON();
      } else if (value instanceof Array) {
        throw new Error("Unsupported array at key=" + key + ", not converting it to url param");
      } else if (type !== "symbol" && type !== "function") {
        params[key] = value.toString();
      } else {
        throw new Error("Unsupported type=" + type + " at key=" + key + ", value=" + value + ", not converting it url param");
      }
    }
    return params;
  }

  /**
   * Save is written to SessionStorage in similar format as in URL,
   * e.g. string => string hash
   */
  private storeState(object: ParamsType) {
    const key = BaseRoute.getSessionStorageKeyName();
    try {
      sessionStorage.setItem(key, JSON.stringify(object));
    } catch (e) {
      console.warn("Error setting state to key", key, e);
    }
  }

  private loadState(): ParamsType {
    const key = BaseRoute.getSessionStorageKeyName();
    try {
      const object = sessionStorage.getItem(key);
      if (object) {
        return JSON.parse(object);
      }
    } catch (e) {
      console.warn("Error loading state for key", key, e);
    }
    return {};
  }

  /**
 * This returns the local path after hostname, with fragment included if present.
 * It should support both pushState-style operation and regular aurelia URL fragment pathing.
 * 
 * @returns part after hostname, excluding query parameters
 */
  private static getCurrentPathWithoutQuery() {
    return location.pathname + (location.hash ? location.hash.replace(/\?.*/, "") : "");
  }

  /**
   * Uses the current action to return suitable key name within (semi)permanent storage
   * where to write saved state.
   * 
   * @returns prefixed key for sessionstorage
   */
  private static getSessionStorageKeyName() {
    return "state-" + BaseRoute.getCurrentPathWithoutQuery();
  }
}
