import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { state } from 'billing/state';
import { ClientTransferTarget, CustomerUpdateRequest, MyHttpApi } from 'utils/api';

@autoinject
export class BillingCustomersEdit {
  private targetId = 0;
  private target?: ClientTransferTarget;
  private customer: CustomerUpdateRequest | undefined;
  private clients: { [key: number]: { name: string; }; } = {};
  private send?: string;
  private state = state;

  constructor(private router: Router, private api: MyHttpApi) {
  }

  async activate(params: { targetId: string, id: string; }) {
    this.targetId = parseInt(params.targetId);
    this.customer = { targetId: this.targetId, country: "FIN", combinedInvoicesList: [], delete: false };
    if (params.id) {
      let [target, customer, clients, combinedClientList] = await Promise.all([
        this.api.clientTransferTargetById({ id: this.targetId }),
        this.api.bookkeepingCustomerById({ id: parseInt(params.id) }),
        this.api.clientList().then(MyHttpApi.toHash),
        this.api.bookkeepingCustomerCombinedInvoicesByCustomer({ id: parseInt(params.id), targetId: this.targetId })
      ]);
      this.target = target;
      this.customer = { ...customer, combinedInvoicesList: combinedClientList, delete: false };
      this.clients = clients;

      if (this.customer.invoiceAddress && (this.customer.invoiceOperator || "SMTP") == "SMTP") {
        this.send = "email";
      } else if (this.customer.invoiceAddress && this.customer.invoiceOperator) {
        this.send = "einvoice";
      } else {
        this.send = "paper";
      }
    } else {
      [this.target, this.clients, this.customer.combinedInvoicesList] = await Promise.all([
        this.api.clientTransferTargetById({ id: this.targetId }),
        this.api.clientList().then(MyHttpApi.toHash),
        this.api.bookkeepingCustomerCombinedInvoicesByCustomer({ id: undefined, targetId: this.targetId })
      ]);
      this.send = 'paper';
    }
  }

  @computedFrom("target.strategy")
  get editable() {
    return (this.target && (this.target.strategy === "TALENOM_ACCOUNTING" || (this.target.strategy === "FENNOA_ACCOUNTING" && !this.target.fennoaTempActive)));
  }

  back() {
    if (this.state.backCustomer) {
      return this.router.navigateToRoute(this.state.backCustomer, this.state.backCustomerParams);
    }
    this.router.navigateToRoute("billing/customers/list", { targetId: "" + this.targetId });
  }
  async delete() {
    if (this.customer) {
      await this.save(true);
    }
  }

  async save(deleted: boolean) {
    if (!this.customer) {
      return;
    }
    if (this.send == "email") {
      this.customer.invoiceOperator = 'SMTP';
    }
    if (this.send == "einvoice") {
      /* einvoice is the default behavior, e.g. have address & operator, and operator is not SMTP */
    }
    if (this.send == "paper") {
      this.customer.invoiceAddress = undefined;
      this.customer.invoiceOperator = undefined;
    }
    await this.api.bookkeepingCustomerUpdate({ ...this.customer, delete: deleted });
    this.back();
  }

  originatedFromInvoice() {
    return this.state.backCustomer == "billing/handle";
  }
}
