/**
 * Helper to saving back-action and handling queue for billing.
 * Will not survive refresh
 */
export interface State {
  incompleteInvoiceList: number[];
  combinedBillingPreviewList: number[];

  back: string;
  backCustomer?: string;
  backCustomerParams?: { [key: string]: string };
}

export let state: State = {
  incompleteInvoiceList: [],
  combinedBillingPreviewList: [],

  back: "billing/sales-invoice-list",
};

/** 
 * Returns next to be handled item if available
 */
export function nextItem(current: number, array: number[]) {
  let idx = array.indexOf(current);
  if (idx+1 < array.length) {
    return array[idx+1];
  }
  return undefined;
}

/** 
 * Returns previous to be handled item if available
 */
export function previousItem(current: number, array: number[]) {
  let idx = array.indexOf(current);
  if (idx-1 >= 0) {
    return array[idx-1];
  }
  return undefined;
}
